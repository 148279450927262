
import { Diagram, ModelConnection, ModelConnectionDirection, ModelObject, ModelObjectType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { capitalize } from '@/helpers/filters'
import { iconUrlForTheme } from '@/helpers/theme'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

interface IHierarchyItem {
  iconUrl?: string
  name: string;
  type: string;
}

interface IModelObject {
  childModelObjects: ModelObject[]
  diagrams: Diagram[]
  iconUrl?: string
  id: string
  modelConnections: { connection: ModelConnection, target: ModelObject, origin: ModelObject }[]
  modelObject: ModelObject
  name: string
}

interface IAction {
  modelObject?: {
    diagramCount: number
    diagramNames: string[]
    flowCount: number
    flowNames: string[]
    iconUrl?: string
    name: string
    type: ModelObjectType
    id: string
  }
  modelConnection?: {
    diagramCount: number
    diagramNames: string[]
    direction: ModelConnectionDirection
    flowCount: number
    flowNames: string[]
    name: string
    origin: ModelObject
    target: ModelObject
    originIconUrl?: string
    targetIconUrl?: string
  }
  flow?: {
    name: string
  }
  diagram?: {
    name: string
  }
}

@Component({
  components: {
    Dialog
  },
  name: 'ModelObjectDeleteDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)

  @Ref() objectScrollRef!: HTMLElement
  @Ref() actionScrollRef!: HTMLElement

  loading = false
  inputValue = ''

  objectCache: IModelObject[] | null = null
  actionCache: IAction[] | null = null

  objectScrollYVisible = false
  actionScrollYVisible = false

  get connectionsLengthAndChildObjectsLength () {
    const connections = this.objects.reduce((sum, o) => sum + o.modelConnections.length, 0)
    const childObjects = this.objects.reduce((sum, o) => sum + o.childModelObjects.length, 0)
    const objects = this.objects.length
    return { childObjects, connections, objects, totalObjects: childObjects + objects }
  }

  get deleteConfirmationMessage () {
    const { childObjects, connections, objects, totalObjects } = this.connectionsLengthAndChildObjectsLength
    const objectLabel = (childObjects + objects === 1) ? 'object' : 'objects'
    const connectionLabel = connections === 1 ? 'connection' : connections > 1 ? 'connections' : ''
    const andLabel = (childObjects + objects) > 0 && connections > 0 ? 'and' : ''

    let message = 'Delete'

    if (totalObjects) message += ` ${totalObjects} ${objectLabel}`
    if (andLabel) message += ` ${andLabel}`
    if (connections) message += ` ${connections} ${connectionLabel}`

    return message
  }

  get skipConfirmationMessage () {
    return (
      this.objects.length === 1 &&
      this.objects[0].modelConnections.length === 0 &&
      this.objects[0].childModelObjects.length === 0 &&
      this.objects[0].diagrams.length <= 1
    )
  }

  get canDelete () {
    return this.skipConfirmationMessage || this.inputValue.trim().toLowerCase() === this.deleteConfirmationMessage.trim().toLowerCase()
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get objectIds () {
    return this.$queryValue('object_delete_dialog')?.split(',') || []
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get objects () {
    if (this.objectCache) {
      return this.objectCache
    } else {
      return this.objectIds
        .map(o => this.modelModule.objects[o])
        .filter(o => o)
        .map((o): IModelObject => {
          const childModelObjects = Object.values(this.modelModule.objects).filter(c => c.parentIds.includes(o.id)).sort((a, b) => a.name.localeCompare(b.name))
          const childModelObjectIds = childModelObjects.map(o => o.id)

          const modelConnections = Object
            .values(this.modelModule.connections)
            .filter(c => c.originId === o.id || c.targetId === o.id || childModelObjectIds.includes(c.originId) || childModelObjectIds.includes(c.targetId))
            .map(o => ({
              connection: o,
              origin: this.modelModule.objects[o.originId],
              target: this.modelModule.objects[o.targetId]
            }))
            .filter(o => o.origin && o.target)
            .sort((a, b) => a.connection.name.localeCompare(b.connection.name))

          const diagrams = Object
            .keys(o.diagrams)
            .map(o => this.diagramModule.diagrams[o])
            .filter(o => o)
            .sort((a, b) => a.name.localeCompare(b.name))

          const name = o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`
          const iconUrl = o.icon ? iconUrlForTheme(o.icon) : undefined

          return {
            childModelObjects,
            diagrams,
            iconUrl,
            id: o.id,
            modelConnections,
            modelObject: o,
            name
          }
        }) || []
    }
  }

  get actions () {
    if (this.actionCache) {
      return this.actionCache
    } else {
      const modelObjects = [
        ...this.objectIds.map(o => this.modelModule.objects[o]).filter(o => o),
        ...this.objectIds.map(o => Object.values(this.modelModule.objects).filter(c => c.parentIds.includes(o))).flat()
      ]
      const modelObjectIds = modelObjects.map(o => o.id)
      const modelConnections = Object.values(this.modelModule.connections).filter(o => modelObjectIds.includes(o.originId) || modelObjectIds.includes(o.targetId))
      const childDiagramIds = modelObjects.map(o => o.childDiagramIds).flat()
      const childDiagrams = childDiagramIds.map(o => this.diagramModule.diagrams[o]).filter(o => o)
      const childDiagramFlows = Object.values(this.flowModule.flows).filter(o => childDiagramIds.includes(o.diagramId))

      return [
        ...modelObjects.map((o): IAction => {
          const icon = o.icon
          return {
            modelObject: {
              diagramCount: Object.keys(o.diagrams).length,
              diagramNames: Object.keys(o.diagrams).map(o => this.diagramModule.diagrams[o]?.name).filter(o => o),
              flowCount: Object.keys(o.flows).length,
              flowNames: Object.keys(o.flows).map(o => this.flowModule.flows[o]?.name).filter(o => o),
              iconUrl: icon ? iconUrlForTheme(icon) : undefined,
              id: o.id,
              name: o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`,
              type: o.type
            }
          }
        }),
        ...modelConnections.map((o): IAction => {
          const originIcon = this.modelModule.objects[o.originId]?.icon
          const targetIcon = this.modelModule.objects[o.targetId]?.icon
          return {
            modelConnection: {
              diagramCount: Object.keys(o.diagrams).length,
              diagramNames: Object.keys(o.diagrams).map(o => this.diagramModule.diagrams[o]?.name).filter(o => o),
              direction: o.direction,
              flowCount: Object.keys(o.flows).length,
              flowNames: Object.keys(o.flows).map(o => this.flowModule.flows[o]?.name).filter(o => o),
              name: o.name || 'Connection',
              origin: this.modelModule.objects[o.originId],
              originIconUrl: originIcon ? iconUrlForTheme(originIcon) : undefined,
              target: this.modelModule.objects[o.targetId],
              targetIconUrl: targetIcon ? iconUrlForTheme(targetIcon) : undefined
            }
          }
        }),
        ...childDiagrams.map((o): IAction => ({
          diagram: {
            name: o.name
          }
        })),
        ...childDiagramFlows.map((o): IAction => ({
          flow: {
            name: o.name
          }
        }))
      ]
    }
  }

  get directionIcons () {
    return (direction: ModelConnectionDirection) => {
      switch (direction) {
        case 'outgoing': return '$fas-long-arrow-alt-right'
        case 'bidirectional': return '$fas-arrows-alt-h'
        default: return '$custom-solid-horizontal-rule'
      }
    }
  }

  @Watch('objects')
  async onObjectsChanged () {
    await this.$nextTick()
    this.objectScrollYVisible = this.objectScrollRef ? this.objectScrollRef.scrollHeight > this.objectScrollRef.clientHeight : false
  }

  @Watch('actions')
  async onActionsChanged () {
    await this.$nextTick()
    this.actionScrollYVisible = this.actionScrollRef ? this.actionScrollRef.scrollHeight > this.actionScrollRef.clientHeight : false
  }

  opened () {
    analytics.modelObjectDeleteDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      modelObjectCount: this.objects.length,
      modelObjectTypes: this.objects.map(o => o.modelObject.type),
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  closed () {
    this.objectCache = null
    this.actionCache = null
    this.inputValue = ''
  }

  capitalizeFirstLetter (str: string) {
    return capitalize(str)
  }

  getLineHeight (index: number, length: number) {
    const heights = {
      2: ['25px'],
      3: ['57px', '27px'],
      4: ['87px', '57px', '27px']
    }
    return heights[length]?.[index - 1] || '0px'
  }

  getHierarchy (objectId: string): IHierarchyItem[] {
    const object = this.modelModule.objects[objectId]
    if (!object) return []

    const domainName = this.domainModule.domains[object.domainId]?.name ?? 'Domain'
    const hierarchy: IHierarchyItem[] = []

    let currentObject: ModelObject | null = object
    while (currentObject) {
      if (currentObject.type === 'root') {
        hierarchy.unshift({ iconUrl: undefined, name: domainName.replace('Default domain', 'Default'), type: 'Domain' })
        break
      }

      const name = currentObject.name ?? `[${this.capitalizeFirstLetter(currentObject.type)}]`
      const type = currentObject.type ? `[${this.capitalizeFirstLetter(currentObject.type)}]` : ''
      const iconUrl = currentObject.icon ? iconUrlForTheme(currentObject.icon) : undefined

      hierarchy.unshift({ iconUrl, name, type })

      const parentId = currentObject.parentIds?.[0] ?? null
      currentObject = parentId && this.modelModule.objects[parentId] ? this.modelModule.objects[parentId] : null
    }
    return hierarchy
  }

  async deleteObject () {
    try {
      this.loading = true

      this.objectCache = this.objects
      this.actionCache = this.actions

      await this.$replaceQuery({
        connection: undefined,
        flow: undefined,
        flow_parent: undefined,
        flow_path: undefined,
        flow_step: undefined,
        object: undefined
      })

      for (const objectId of this.objectIds) {
        if (this.currentDiagram?.status === 'draft' && this.currentDiagramContent) {
          const connectionIds = Object
            .values(this.modelModule.connections)
            .filter(o => o.targetId === objectId || o.originId === objectId)
            .map(o => o.id)

          const diagramObjectIds = Object
            .values(this.currentDiagramContent.objects)
            .filter(o => o.modelId === objectId)
            .map(o => o.id)

          const diagramConnectionIds = Object
            .values(this.currentDiagramContent.connections)
            .filter(o =>
              (o.modelId && connectionIds.includes(o.modelId)) ||
              (o.targetId && diagramObjectIds.includes(o.targetId)) ||
              (o.originId && diagramObjectIds.includes(o.originId))
            )
            .map(o => o.id)

          const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
            connections: {
              $remove: diagramConnectionIds
            },
            objects: {
              $remove: diagramObjectIds
            },
            tasksProposed: {
              $append: [
                ...connectionIds.map((o): Task => ({
                  id: o,
                  type: 'model-connection-delete'
                })),
                {
                  id: objectId,
                  type: 'model-object-delete'
                }
              ]
            }
          })
          await this.diagramModule.diagramContentUpdate({
            diagramId: diagramContent.id,
            landscapeId: this.currentLandscape.id,
            props: diagramContentUpdate,
            versionId: this.currentDiagram.versionId
          })
        } else {
          await this.modelModule.objectDelete({
            landscapeId: this.currentLandscape.id,
            objectId,
            versionId: this.currentVersion.id
          })
        }
      }

      this.editorModule.resetTaskLists()

      if (this.currentDiagram) {
        await this.diagramModule.diagramContentFind({
          diagramId: this.currentDiagram.id,
          landscapeId: this.currentLandscape.id,
          versionId: this.currentVersion.id
        })
      }

      this.$emit('delete', this.objectIds)

      await this.$replaceQuery({
        object_delete_dialog: undefined
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
      throw err
    } finally {
      this.loading = false
    }
  }
}
